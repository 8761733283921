<template>
  <v-container id="reset-password-confirm-view" class="fill-height" tag="section">
    <v-row justify="center">
      <v-col cols="12" md="9" lg="6">
        <v-slide-y-transition appear>
          <v-card class="pa-3 pa-md-10 mx-sm-auto" light>
            <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
              <v-form ref="form" autocomplete="on" @submit.prevent="handleSubmit(changePassword)">
                <v-card-text class="text-center">
                  <div class="text-center font-weight-light"></div>

                  <validation-provider name="password1" rules="required" :debounce="200">
                    <v-text-field class="mt-10" id="new-password" v-model="newPassword1" autocomplete="new-password"
                      color="secondary" type="password" :placeholder="$t('signin.new.password')" />
                  </validation-provider>

                  <validation-provider name="password1" rules="required" :debounce="200">
                    <v-text-field class="mb-8" color="secondary" v-model="newPassword2"
                      :placeholder="$t('signin.new.password.confirm')" autocomplete="new-password" type="password"
                      v-on:keyup.enter="resetPassword" />
                  </validation-provider>

                  <v-btn color="secondary" depressed rounded large :disabled="invalid" :loading="loading" type="submit"
                    class="mr-2">
                    {{ $t("signin.change.your.password") }}
                  </v-btn>
                  <v-btn color="secondary" depressed rounded large text @click="signin">
                    {{ $t("signin.button") }}
                  </v-btn>
                </v-card-text>
              </v-form>
            </validation-observer>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { email } from "vee-validate/dist/rules"
import { changePassword } from '@/services/auth.service'

import vue from "vue"
// import router from '@/router'
export default {
  data: () => ({
    socials: [
      {
        href: "#",
        icon: "mdi-facebook"
      },
      {
        href: "#",
        icon: "mdi-twitter"
      },
      {
        href: "#",
        icon: "mdi-github"
      }
    ],
    loading: false,
    newPassword1: null,
    newPassword2: null
  }),
  beforeMount() {
  },
  methods: {
    signin() {
      this.$router.push({ name: "SignIn" })
    },
    changePassword() {
      if (this.loading) {
        return
      }
      if (this.$refs.form.validate()) {
        this.loading = true
        changePassword(this.newPassword1, this.newPassword2, this.$route.params.uid, this.$route.params.token)
          .then(response => {
            this.$dialog.notify.info("Hasło zostało zmienione", {
              timeout: 10000
            })
            vue.$log.info("success", response)
            this.signin()
          })
          .catch(error => {
            this.loading = false
            console.log('error :>> ', error.response);
            const data = error.response?.data
            if (data == null) {
              return;
            }
            if (data.token) {
              this.$dialog.notify.error("Link jest już nieważny", {
                timeout: 10000
              })
            } else if (data.new_password2)
            for (const message of data.new_password2) {
              this.$dialog.notify.error(message, {
                timeout: 10000
              })
            }
          })
      }
      return false
    }
  }
}
</script>
